@use '@angular/material'as mat;
@import "node_modules/bootstrap/scss/bootstrap";
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

:root {


  --primary-color: #002484;
  --dark-green: #2D7C36;

  --light-green: #8DBF3E;
  --general-background-color: #8DBF3E;

  --warning-color: #cc3300;
  --primary-orange: #E89610;

  --primary-grey: #969696;
  --secondary-grey: #ccc5b9;
  --tertiary-grey: #666666;


  --primary-black: #292929;
  --primary-white: #ffffff;


}


// font di base che saranno poi usati nel progetto da definire
@font-face {
  font-family: "Raleway-light", sans-serif;
  src: local("Raleway-light"), url(/assets/fonts/Raleway/Raleway-VariableFont-light.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-light-italic", sans-serif;
  src: local("Raleway-light-italic"), url(/assets/fonts/Raleway/Raleway-Italic-light.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-regular", sans-serif;
  src: local("Raleway-regular"), url(/assets/fonts/Raleway/Raleway-VariableFont-regular.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-regular-italic", sans-serif;
  src: local("Raleway-regular-italic"), url(/assets/fonts/Raleway/Raleway-Italic-regular.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-bold", sans-serif;
  src: local("Raleway-bold"), url(/assets/fonts/Raleway/Raleway-VariableFont-regular.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-bold-italic", sans-serif;
  src: local("Raleway-bold-italic"), url(/assets/fonts/Raleway/Raleway-Italic-regular.ttf) format("truetype");
}



// hue. Available color palettes: https://material.io/design/color/
$ecoxxi-primary: mat.define-palette(mat.$indigo-palette);
$ecoxxi-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$ecoxxi-warn: mat.define-palette(mat.$red-palette);

// theming systems such as "color" or "typography".
$ecoxxi-theme: mat.define-light-theme((color: (primary: $ecoxxi-primary,
      accent: $ecoxxi-accent,
      warn: $ecoxxi-warn,
    )));

@include mat.all-legacy-component-themes($ecoxxi-theme);
$custom-typography: mat.define-legacy-typography-config($font-family: "Raleway-light, Raleway-light-italic. Raleway-regular, Raleway-regular-italic, Raleway-bold,Raleway-bold-italic",
  $input: mat.define-typography-level(inherit, 1.25, 400),
);

@include mat.all-legacy-component-typographies($custom-typography);

// FINE IMPOSTAZIONI COLORI E FONT


html,

body {

  height: 100vh;

}

body {

  margin: 0;
  padding: 0;

  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;

}

.mat-bottom-sheet-container {

  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;

}



mat-icon.mat-icon.tari-icon{

  color: var(--primary-color);
  font-size: 17px;

}

.tari{
  top: -10px;
  right: 0;
}



.container{
  max-width: 900px;
}

//mat-checkbox

.mat-checkbox-checked.mat-accent .mat-checkbox-background{

  background-color: var(--primary-color)!important;

}

// Classi generiche

.disabled{
  opacity: 0.3;
}


.b-radius {
  border-radius: 10px;
}

.separetor{
  height: 1.5px;
  background-color: var(--dark-green);
}

.card-border{
  border: 2px solid var(--light-green);
  width: 95%;
  margin: auto;
  max-height: 500px;
}


// font


.font-weight-light{

  font-weight: 100;

}


.font-weight-medium{

  font-weight: 600;

}

.font-weight-bolder{

  font-weight: 900;

}

.font-style-italic{

  font-style: italic;

}


.eco-21-cntr {

  // TESTI

  h1 {

    font-size: 26px;

  }

  h2 {

    font-size: 20px;
    font-style: italic;

  }


  @media screen and (max-width: 500px){


    h1{

      font-size: 23px;

    }

  }

  @media screen and (max-width: 391px){

     h2, h3{

      font-size: 17px;

    }

    p, span, mat-label, div {

      font-size: 12px;

    }

  }

}

// colori


.primary-color {

  color: var(--primary-color);

}

.light-green{

  color: var(--light-green);

}

.dark-green{

  color: var(--dark-green);

}

.warning-color{
  color: var(--warnning-color);
}

.orange-color{
  color: var(--primary-orange);
}




// BOTTONI

.general-button {

  cursor: pointer;

  width: 100%;
  max-width: 250px;
  letter-spacing: 1px;
  height: 40px;
  line-height: 40px;
  border-radius: 15px;
  border: none;
  font-style: italic;
  font-weight: 700;
  color: var(--primary-white);
  background-color: var(--light-green);
  text-align: center;

}

@media screen and (max-width: 500px){

  .general-button{

    font-size: 12px

  }

}




// Registration avatars classes

.jello-horizontal {
  -webkit-animation: jello-horizontal .9s both;
  animation: jello-horizontal .9s both
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
  }

  30% {
    -webkit-transform: scale3d(1.25, .75, 1);
    transform: scale3d(1.25, .75, 1)
  }

  40% {
    -webkit-transform: scale3d(.75, 1.25, 1);
    transform: scale3d(.75, 1.25, 1)
  }

  50% {
    -webkit-transform: scale3d(1.15, .85, 1);
    transform: scale3d(1.15, .85, 1)
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1)
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1)
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
  }
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
  }

  30% {
    -webkit-transform: scale3d(1.25, .75, 1);
    transform: scale3d(1.25, .75, 1)
  }

  40% {
    -webkit-transform: scale3d(.75, 1.25, 1);
    transform: scale3d(.75, 1.25, 1)
  }

  50% {
    -webkit-transform: scale3d(1.15, .85, 1);
    transform: scale3d(1.15, .85, 1)
  }

  65% {
    -webkit-transform: scale3d(.95, 1.05, 1);
    transform: scale3d(.95, 1.05, 1)
  }

  75% {
    -webkit-transform: scale3d(1.05, .95, 1);
    transform: scale3d(1.05, .95, 1)
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1)
  }
}

//procione
.ecoxxi-avatar.avatar-1 {
  background-image: url(/assets/images/avatars-ecoxxi/procione.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.ecoxxi-avatar.avatar-1.off {

  opacity: 0.3;

}


//gatto

.ecoxxi-avatar.avatar-2 {
  background-image: url(/assets/images/avatars-ecoxxi/gatto.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.ecoxxi-avatar.avatar-2.off {
  opacity: 0.3;
}







